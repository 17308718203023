<template>
  <div class="app">
    <img
      src="../../assets//allImage/homePage/toutu_xinwenzhognxin@1x.png"
      alt=""
    />
    <!-- <div>{{ list }}</div> -->
    <div>
      <!-- 新闻详情 -->
      <div>
        <div class="app-content">
          <h4>{{ formobj.title }}</h4>
          <div class="heads">
            <div class="timeData">
              <span>时间：</span>
              <span class="commSyl">{{ formobj.updateTime }}</span>
            </div>
            <div class="pageView">
              <span> 浏览次数：</span>
              <span class="commSyl"> {{ formobj.viewsNumbers }}</span>
            </div>
            <div>
              <span>信息来源：</span>
              <span class="commSyl">{{ formobj.source }}</span>
            </div>
          </div>
          <div class="listSon">
            <div class="deatils" v-html="formobj.content"></div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { articleInterface, companyArticleDetails } from '../../api/index';
import { mapState } from 'vuex';
export default {
  data() {
    return {
      form: {
        id: '',
      },
      formobj: {},
      ty: '',
      loc: '',
      list: [],
      detailArrayList: [],
      myId: '',
      listArr: [],
      currentIndex: '', //当前索引
    };
  },

  computed: {
    ...mapState(['articleDetails']),
    ...mapState(['Details']),
    ...mapState(['upAndDown']),
    ...mapState(['bomAndDown']),
  },
  created() {
    // this. = window.location.href;
    this.form.id = this.$route.query.id;
    // this.form.columnId = this.$route.query.columnId;
  },
  mounted() {
    this.getlist();
  },
  methods: {
    async getlist() {
      const res = await articleInterface(this.form.id);
      this.formobj = res.data.data;
    },
  },
};
</script>

<style lang="scss" scoped>
.deatils {
  text-indent: 0 !important;
}
img {
  max-width: 100% !important;
}
.commSyl {
  color: #c1c1c1;
}
* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}
.app {
  width: 100%;
  background-color: #f5f5f5;
  padding: 1.25rem 0;
  .app-content {
    width: 60%;
    padding: 2.5rem 0.9375rem;
    background-color: #fff;
    border-radius: 0.25rem;
    margin: 0.625rem auto;
    overflow: hidden;
    img {
      width: 100%;
    }
    h4 {
      width: 100%;
      height: 2.1875rem;
      line-height: 2.1875rem;
    }
    .heads {
      width: 100%;
      height: 2.8125rem;
      border-bottom: 0.0625rem solid black;
      display: flex;
      align-items: center;
      justify-content: center;
      font-size: 0.75rem;
      .timeData {
        margin-right: 0.9375rem;
      }
      .pageView {
        margin-right: 0.9375rem;
      }
    }
    .listSon {
      width: 100%;
      line-height: 2.1875rem;
      padding: 0.9375rem;
      text-align: left;
      .deatils {
        text-indent: 2rem;
      }
    }
  }
  .thePrecedingAndSubsequentArticles {
    width: 60%;
    height: 2.8125rem;
    margin: 0 auto;
    text-align: left;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .box_left {
      font-size: 0.75rem;
      .son_lt {
        color: #c1c1c1;
        cursor: pointer;
      }
    }
    .box_right {
      font-size: 0.75rem;
      .son_Rt {
        color: #c1c1c1;
        cursor: pointer;
      }
    }
  }
}
</style>
